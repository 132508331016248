export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kardex"])},
        "FIELDS": {
          "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])},
          "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de fin"])}
        },
        "PH": {
          "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])},
          "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de fin"])}
        }
      }
    }
  })
}
