<script>
import {computed, defineComponent, ref} from "vue";
import ModalFormMaster from "../../components/c-modal-form/ModalFormMaster.vue";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import {Field, useForm} from "vee-validate";
import * as Validations from "yup";
import {getModule} from "vuex-module-decorators";
import NewProductEntryModule from "@/store/modules/productEntry/modules/new-product-entry";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue";
import ApiService from "@/core/services/ApiService";
import {getMessageError} from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";

export default defineComponent({
    name: "download-report",
    components: {
        SelectFilterRemote,
        ModalFormMaster,
        RowForm,
        Field
    },
    setup() {
        let selectedProduct = ref(null)
        let listProduct = ref([])
        let listProductName = ref([])
        let selectedSupplier = ref(null)
        let listSupplier = ref([])
        let listSupplierName = ref([])

        const modalForm = ref(null);

        let suppliers = ref([]);
        const comboSupplier = computed(() => {
            return suppliers.value
        });
        const messageService = new SwalMessageService();

        const moduleProduct = getModule(NewProductEntryModule);
        const productsCombo = computed(() => moduleProduct.productOptions);
        const productsLoading = computed(() => moduleProduct.loadings.productSelect);

        const validationSchema = Validations.object().shape({
            productId: Validations.string().label("productId")
                .test("is-duplicate-product", "El producto ya se encuentra en la lista", function (value) {
                    return !listProduct.value.some(product => product === value);
                }),
            supplierId: Validations.string().label("supplierId")
                .test("is-duplicate-supplier", "El proveedor ya se encuentra en la lista", function (value) {
                    return !listSupplier.value.some(supplier => supplier === value);
                }),
            Start: Validations.string().required("La fecha de inicio es obligatoria").label("startDate")
                .test(
                    "date-in-current-year",
                    "La fecha debe de ser del año en curso",
                    function (value) {
                        return isDateInCurrentYear(value);
                    }
                ),
            End: Validations.string().required("La fecha de fin es obligatoria").label("endDate")
                .test(
                    "date-correct",
                    "Por favor, asegúrate de que la fecha de fin sea posterior o igual a la fecha de inicio",
                    function (value) {
                        const {Start} = this.parent;  // Accedemos a la fecha de inicio
                        return new Date(value) >= new Date(Start);  // Comparamos las fechas
                    }
                )
                .test(
                    "date-in-current-year",
                    "La fecha debe de ser del año en curso",
                    function (value) {
                        return isDateInCurrentYear(value);
                    }
                ),
        });

        // Función para verificar si la fecha está dentro del año en curso
        const isDateInCurrentYear = (date) => {
            const currentYear = new Date().getFullYear();
            const givenYear = new Date(date).getFullYear();
            return currentYear === givenYear;
        };

        const { validate } = useForm({
            validationSchema,
        });

        const handleSearchProducts = (name) => {
            if (name.trim().length >= 3) {
                moduleProduct.SEARCH_PRODUCTS(name);
            }
        }

        const getComboOptions = async (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?Criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const searchSuppliers = async (filter) => {
            if (filter.trim().length >= 3) {
                await getComboOptions("suppliers", suppliers, "", filter);
            }
        }

        const initDefaults = () => {
            modalForm.value.$refs.form.setFieldValue("productId", "");
            modalForm.value.$refs.form.setFieldValue("supplierId", "");
            modalForm.value.$refs.form.setFieldValue("Start", "");
            modalForm.value.$refs.form.setFieldValue("End", "");
            modalForm.value.$refs.form.resetForm();

            selectedProduct.value = null
            listProduct.value = []
            listProductName.value = []
            selectedSupplier.value = null
            listSupplier.value = []
            listSupplierName.value = []
        }

        const addProduct = async () => {
            const productExists = listProduct.value.some(product => product === selectedProduct.value);

            if (productExists)
                return;

            listProduct.value.push(selectedProduct.value);
            getProduct(selectedProduct.value)

            modalForm.value.$refs.form.setFieldValue("productId", "");
            selectedProduct.value = null
        }

        const addSupplier = async () => {
            const productExists = listSupplier.value.some(supplier => supplier === selectedSupplier.value);

            if (productExists)
                return;

            listSupplier.value.push(selectedSupplier.value);
            getSupplier(selectedSupplier.value)

            modalForm.value.$refs.form.setFieldValue("supplierId", "");
            selectedSupplier.value = null
        }

        const getProduct = async (productId) => {
            let url = `Products/${productId}`;
            ApiService.get(url).then(res => {
                if (res.status == 200) {
                    listProductName.value.push({
                        id: productId,
                        name: res.data.name
                    })
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getSupplier = async (supplierId) => {
            let url = `Suppliers/${supplierId}`;
            ApiService.get(url).then(res => {
                if (res.status == 200) {
                    listSupplierName.value.push({
                        id: supplierId,
                        name: res.data.name
                    })
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleProductChange = (value) => {
            selectedProduct.value = value;
        };

        const handleSupplierChange = (value) => {
            selectedSupplier.value = value;
        };

        const eliminarProduct = (product) => {
            listProductName.value = listProductName.value.filter(x => x.id !== product);
            listProduct.value = listProduct.value.filter(x => x !== product);
        }

        const eliminarSupplier = (supplier) => {
            listSupplierName.value = listSupplierName.value.filter(x => x.id !== supplier);
            listSupplier.value = listSupplier.value.filter(x => x !== supplier);
        }

        const handleCrudAction = async (product) => {
            await serviceReport(product.Start, product.End, listSupplier.value, listProduct.value);

            modalForm.value.closeModal();
        }

        const serviceReport = async (inicio, final, supplier, product) => {

            let urls = `Reports/Replenishment?StartDate=${inicio}&EndDate=${final}`;

            // Si hay productos, los añadimos a la URL
            if (product.length > 0) {
                urls += `&${product.map(productId => `Products=${productId}`).join('&')}`;
            }

            // Si hay proveedores, los añadimos a la URL
            if (supplier.length > 0) {
                urls += `&${supplier.map(supplierId => `Supplier=${supplierId}`).join('&')}`;
            }

            const res = await ApiService.dowloadFileGet(urls);
            const url = window.URL.createObjectURL(res.data);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'reporte_reabastecimiento.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        };

        return {
            modalForm
            , validationSchema
            , productsCombo
            , productsLoading
            , comboSupplier
            , listProductName
            , listSupplierName
            , selectedProduct
            , selectedSupplier

            //Computeds

            //Functions
            , handleSearchProducts
            , initDefaults
            , handleCrudAction
            , searchSuppliers
            , addProduct
            , handleProductChange
            , addSupplier
            , handleSupplierChange
            , eliminarProduct
            , eliminarSupplier
        }
    },
});
</script>

<template>
    <modal-form-master
        id="modalDownloadReport"
        :title="$t('TITLE')"
        :readOnly="false"
        :textSaveBtn="'Descargar'"
        :validationSchema="validationSchema"
        :fnCallbackSubmit="handleCrudAction"
        ref="modalForm"
    >
        <row-form>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.PRODUCT") }}</label>
            <div class="d-flex gap-5">
                <SelectFilterRemote
                    class="largeItemControl2"
                    :name="'productId'"
                    :placeholder="'Búscar por nombre del producto'"
                    :options="productsCombo"
                    :loading="productsLoading"
                    @change="handleProductChange"
                    @search="handleSearchProducts">
                </SelectFilterRemote>
                <el-button @click="addProduct" :disabled="selectedProduct == null">
                    Agregar
                </el-button>
            </div>
            <div class="box-selected" v-if="listProductName.length > 0">
                <div v-for="products in listProductName" :key="products.id" class="d-flex justify-content-between">
                    {{ products.name }}
                    <div class="btnDelete" @click="eliminarProduct(products.id)">
                        x
                    </div>
                </div>
            </div>
        </row-form>

        <row-form>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.SUPPLIER") }}</label>
            <div class="d-flex gap-5">
                <SelectFilterRemote
                    class="largeItemControl2"
                    :name="'supplierId'"
                    :placeholder="'Búscar por nombre del proveedor'"
                    :options="comboSupplier"
                    @change="handleSupplierChange"
                    @search="searchSuppliers">
                </SelectFilterRemote>
                <el-button @click="addSupplier" :disabled="selectedSupplier == null">
                    Agregar
                </el-button>
            </div>
            <div class="box-selected" v-if="listSupplierName.length > 0">
                <div v-for="supplier in listSupplierName" :key="supplier.id" class="d-flex justify-content-between">
                    {{ supplier.name }}
                    <div class="btnDelete" @click="eliminarSupplier(supplier.id)">
                        x
                    </div>
                </div>
            </div>
        </row-form>

        <div class="row tamano">
            <div class="col-md-6 mb-2">
                <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.START") }}</label>
                <Field name="Start" v-slot="{ value, field, errorMessage }">
                    <el-form-item :error="errorMessage">
                        <el-date-picker
                            :model-value="value"
                            v-bind="field"
                            format="DD/MM/YYYY"
                            value-format="YYYY-MM-DD"
                            type="text"
                            name="startDate"
                            class="itemControl"
                        />
                    </el-form-item>
                </Field>
            </div>
            <div class="col-md-6 mb-2">
                <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.END") }}</label>
                <Field name="End" v-slot="{ value, field, errorMessage }">
                    <el-form-item :error="errorMessage">
                        <el-date-picker
                            :model-value="value"
                            v-bind="field"
                            format="DD/MM/YYYY"
                            value-format="YYYY-MM-DD"
                            type="text"
                            name="endDate"
                            class="itemControl"
                        />
                    </el-form-item>
                </Field>
            </div>
        </div>
    </modal-form-master>
</template>

<style lang="scss">
.itemControl {
    width: 272px;
}

.largeItemControl {
    width: 553px;
}

.largeItemControl2 {
    width: 447px;
}

.tamano {
    width: 573px !important;
}

.box-selected {
    width: 553px;
    margin-bottom: 15px;
}

.btnDelete {
    width: 16px;
    height: 16px;
    border-radius: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btnDelete:hover {
    background-color: #519ded;
}
</style>

<i18n>
{
    "es": {
        "TITLE": "Reporte de reabastecimiento de productos",
        "FIELDS": {
            "PRODUCT": "Producto",
            "SUPPLIER": "Proveedor",
            "START": "Fecha de inicio",
            "END": "Fecha de fin"
        },
        "PH": {
            "PRODUCT": "Producto",
            "SUPPLIER": "Proveedor",
            "START": "Fecha de inicio",
            "END": "Fecha de fin"
        }
    }
}
</i18n>