<script>
import {computed, defineComponent, ref} from "vue";
import ModalFormMaster from "../../components/c-modal-form/ModalFormMaster.vue";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import {Field, useForm} from "vee-validate";
import * as Validations from "yup";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue";
import {getModule} from "vuex-module-decorators";
import NewProductEntryModule from "@/store/modules/productEntry/modules/new-product-entry";
import PDFViewer from "@/components/modals/general/PDFViewer.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
    name: "download-kardex",
    components: {
        PDFViewer,
        SelectFilterRemote,
        ModalFormMaster,
        RowForm,
        Field
    },
    setup() {
        const modalForm = ref(null);

        const openPDF = ref(null);
        const pdf = ref("");

        const moduleProduct = getModule(NewProductEntryModule);
        const productsCombo = computed(() => moduleProduct.productOptions);
        const productsLoading = computed(() => moduleProduct.loadings.productSelect);

        const validationSchema = Validations.object().shape({
            productId: Validations.string().required().label("productId"),
            Start: Validations.string().required("La fecha de inicio es obligatoria").label("startDate"),
            End: Validations.string().required("La fecha de fin es obligatoria").label("endDate")
                .test(
                    "date-correct",
                    "Por favor, asegúrate de que la fecha de fin sea posterior o igual a la fecha de inicio",
                    function (value) {
                        const { Start } = this.parent;  // Accedemos a la fecha de inicio
                        return new Date(value) >= new Date(Start);  // Comparamos las fechas
                    }
                ),
        });

        const { validate } = useForm({
            validationSchema,
        });

        const handleSearchProducts = (name) => {
            if(name.trim().length >= 3){
                moduleProduct.SEARCH_PRODUCTS(name);
            }
        }

        const initDefaults = () => {
            modalForm.value.$refs.form.setFieldValue("productId", "");
            modalForm.value.$refs.form.setFieldValue("Start", "");
            modalForm.value.$refs.form.setFieldValue("End", "");
            modalForm.value.$refs.form.resetForm();
        }

        const handleCrudAction = async(product) => {
            await serviceKardex(product.productId, product.Start, product.End);
            modalForm.value.closeModal();
        }

        const handleViewRecib = async () => {
            if(openPDF.value) {
                openPDF.value.click();
            }
        }

        const serviceKardex = async (id, inicio, final) => {
            let url = `Reports/Kardex?Id=${id}&Inicio=${inicio}&Final=${final}`;

            const res = await ApiService.query(url);
            pdf.value = "data:application/pdf;base64," + res.data;
            await handleViewRecib();
        };

        return {
            modalForm
            , validationSchema
            , productsCombo
            , productsLoading
            , pdf
            , openPDF

            //Computeds

            //Functions
            , handleSearchProducts
            , initDefaults
            , handleCrudAction
        }
    },
});
</script>

<template>
    <modal-form-master
        id="modalDownloadKardex"
        :title="$t('TITLE')"
        :readOnly="false"
        :textSaveBtn="'Descargar'"
        :validationSchema="validationSchema"
        :fnCallbackSubmit="handleCrudAction"
        ref="modalForm"
    >
        <row-form>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.PRODUCT") }}</label>
            <SelectFilterRemote
                class="largeItemControl"
                :name="'productId'"
                :placeholder="'Búscar por nombre del producto'"
                :options="productsCombo"
                :loading="productsLoading"
                @search="handleSearchProducts"
                >
            </SelectFilterRemote>
        </row-form>

        <div class="row tamano">
            <div class="col-md-6 mb-2">
                <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.START") }}</label>
                <Field name="Start" v-slot="{ value, field, errorMessage }">
                    <el-form-item :error="errorMessage">
                        <el-date-picker
                            :model-value="value"
                            v-bind="field"
                            format="DD/MM/YYYY"
                            value-format="YYYY-MM-DD"
                            type="text"
                            name="startDate"
                        />
                    </el-form-item>
                </Field>
            </div>
            <div class="col-md-6 mb-2">
                <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.END") }}</label>
                <Field name="End" v-slot="{ value, field, errorMessage }">
                    <el-form-item :error="errorMessage">
                        <el-date-picker
                            :model-value="value"
                            v-bind="field"
                            format="DD/MM/YYYY"
                            value-format="YYYY-MM-DD"
                            type="text"
                            name="endDate"
                        />
                    </el-form-item>
                </Field>
            </div>
        </div>
    </modal-form-master>
    <PDFViewer :base64="pdf" :titulo="'Kardex'" :fileName="'Kardex'" ></PDFViewer>
    <button
        hidden
        ref="openPDF"
        class="btn btn-primary er fs-6 "
        data-bs-toggle="modal"
        data-bs-target="#viewPDF"
    >
        {{ $t("BUTTOMS.NEW_SETTLEMENT") }}
    </button>
</template>

<style lang="scss">
.itemControl {
    width: 272px;
}

.largeItemControl {
    width: 553px;
}

.tamano{
    width: 573px !important;
}
</style>

<i18n>
{
    "es": {
        "TITLE": "Kardex",
        "FIELDS": {
            "PRODUCT": "Producto",
            "START": "Fecha de inicio",
            "END": "Fecha de fin"
        },
        "PH": {
            "PRODUCT": "Producto",
            "START": "Fecha de inicio",
            "END": "Fecha de fin"
        }
    }
}
</i18n>