<template>
<ModuleMaster 
    :requireSearchField="true"
    :requireAddButton="false"
    :requirePaginator="false"
    :labels="labels"
    :numItemsPaginator="totalRecords"
    :selectedPage="selectedPage"
    :itemsPerPage="5"
    :fnCallbackChangePage="handleChangePage"
    :fnCallbackCrudAction="handleCrudAction"
    :fnCallbackSearchItems="searchItems"
>
    <template v-slot:extra-buttons>
        <main-toolbar @onClickToolbarAction="toolbarAction"/>
    </template>
    <Datatable :headers="headers" :requireActions="true" :hover="true" :bodySemibold="true">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="false"
            :statusPropName="'status'"
            :callbackColumnClick="callbackColumnClick"
        >
            <CrudActions :itemID="row.ID" :iconSize="2" :callbackCrudAction="handleCrudAction" :requireM="row.status"/>
        </Datarow>
    </Datatable>
    <div class="paginator">
        <PaginatorV2 
            :numItems="totalRecords" 
            :itemsPerPage="recordsPerPage"
            :selectedPage="selectedPage"
            @handleClickPage="handleChangePage"
            @change-page-size="changePageSize"
        />
    </div>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalCatalogFilters" ref="btnFilters"></button>
<catalog-filters ref="formCatalogFilters" @onApplyFilters="onApplyFilters" @onCleanFilters="onCleanFilters"/>
<button hidden data-bs-toggle="modal" data-bs-target="#modalDownloadKardex" ref="btnDownloadKardex"></button>
<download-kardex ref="formDownloadKardex"/>
<button hidden data-bs-toggle="modal" data-bs-target="#modalDownloadReport" ref="btnDownloadReport"></button>
<download-report ref="formDownloadReport"/>
<button hidden data-bs-toggle="modal" data-bs-target="#modalChangePrices" ref="btnChangePrices"></button>
<change-prices ref="formChangePrices"/>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../components/c-module-master/module-master.vue";
import CrudActions from "../../components/c-module-master/crud-actions.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../common/formaterToDatatable";
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import MainToolbar from "./main-toolbar.vue";
import { useRouter } from "vue-router";
import ChangePrices from "./change-prices.vue";
import CatalogFilters from "./catalog-filters.vue";
import DownloadKardex from "./download-kardex.vue";
import DownloadReport from "./download-report.vue";
import PaginatorV2 from "../../components/c-paginator/PaginatorV2.vue"

export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
        , CrudActions
        , MainToolbar
        , ChangePrices
        , CatalogFilters
        , PaginatorV2
        , DownloadKardex
        , DownloadReport
    },
    setup() {
        //VARIABLES Y CONSTANTES
        const controller = "Products";
        const router = useRouter();
        const messageService = new SwalMessageService();
        let records = ref([]);
        let currentPage = ref(1);
        let cantRecordsPage = ref(25);
        let totalItems = ref(0);
        let textSearch = ref("");
        let criteriaFilters = "";
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Agregar Artículo",
        });
        const recordsPerPage = 5;
        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false }
            //, { PropName: "invoince", HeadLabel: "Folio", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "code", HeadLabel: "Código", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "name", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true, Bold: true, canClicked: true }
            , { PropName: "familyName", HeadLabel: "Familia", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "subFamilyName", HeadLabel: "Clase", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "lineName", HeadLabel: "Línea", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "unitOfMeasurementName", HeadLabel: "Unidad", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "publicPrice", HeadLabel: "Precio Público", Type: "moneda", VisibleInGrid: true, Bold: true, Center: true }
            , { PropName: "buyerPrice", HeadLabel: "Precio Aluminiero", Type: "moneda", VisibleInGrid: true, Bold: true, Center: true }
            , { PropName: "wholesalePrice", HeadLabel: "Precio Mayoreo", Type: "moneda", VisibleInGrid: true, Bold: true, Center: true }
            , { PropName: "currentExistence", HeadLabel: "Existencias", Type: "text", VisibleInGrid: true, Bold: true,  Center: true }
            , { PropName: "statusName", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true, Bold: true, Badge: true }
            , { PropName: "status", HeadLabel: "", Type: "check", VisibleInGrid: false }
        ]);

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Artículos", ["Catálogos"]);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable, "status");
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const btnFilters = ref(null);
        const formCatalogFilters = ref(null);
        const btnChangePrices = ref(null);
        const formChangePrices = ref(null);
        const btnDownloadKardex = ref(null);
        const formDownloadKardex = ref(null);
        const btnDownloadReport = ref(null);
        const formDownloadReport = ref(null);

        //FUNCIONES
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            await getPagedItems();
        }
        
        const changePageSize = async (newPageSize) => {
            cantRecordsPage.value = newPageSize;
            await getPagedItems();
         }

        const getPagedItems = async() => {
            let criteriaSearch = `Criteria.Active=true${textSearch.value.trim() != "" ? `&Criteria.Name=${textSearch.value.trim()}` : ""}`;
            criteriaSearch += `${criteriaFilters != "" ? "&" : ""}${criteriaFilters}`;
            let url = `${controller}/GetPagedList?currentPage=${currentPage.value}&PageSize=${cantRecordsPage.value}&${criteriaSearch}`;
            ApiService.query(url).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data.map(d => {d.statusName = d.status ? 'Activo' : 'Inactivo'; return d; });
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleCrudAction = async(action, itemID) => {
            switch(action){
                case "V":
                case "M":
                    goToArticleModule(action, itemID);
                    break;
                case "E":
                    changeArticleStatus(itemID);
                    break;
            }
            
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const callbackColumnClick = (rowID) => {
            goToArticleModule("V", rowID);
        }

        const goToArticleModule = (mode, rowID) => {
            let dataProps = `{"articleId":"${rowID}", "articleCode": "", "action": "${mode}"}`;
            router.push({
                name: 'editarticles',
                params: {
                    C0: btoa(dataProps)
                }
            });
        }

        const toolbarAction = (action) => {
            switch(action) {
                case "F":
                    if(btnFilters.value) {
                        formCatalogFilters.value.initDefaults();
                        btnFilters.value.click();
                    }
                    break;
                case "EXP":
                    // if(btnChangePrices.value) {
                    downloadcsvpodruct();
                    // }
                    break;
                case "DL":
                    downloadLayout();
                    break;
                case "UP":
                    if(btnChangePrices.value) {
                        formChangePrices.value.initDefaults();
                        btnChangePrices.value.click();
                    }
                    break;
                case "KX":
                    if(btnDownloadKardex.value) {
                        formDownloadKardex.value.initDefaults();
                        btnDownloadKardex.value.click();
                    }
                    break;
                case "RP":
                    if(btnDownloadReport.value) {
                        formDownloadReport.value.initDefaults();
                        btnDownloadReport.value.click();
                    }
                    break;
            }
        }

        const changeArticleStatus = async(itemID) => {
            let item = records.value.find(i => i.id === itemID);
            if(item) {
                Swal.fire({
                    title: `Está a punto de ${ item.status ? "desactivar" : "activar"} el registro. ¿Desea continuar?`,
                    icon: "warning",
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: 'Sí',
                    cancelButtonText: 'No',
                    denyButtonText: 'No',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let activate = !item.status;
                            let url = `${controller}/${item.id}/${activate ? 'activate' : 'deactivate'}`;
                            ApiService.put(url).then(res => {
                                item.status = activate;
                                item.statusName = activate ? "Activo" : "Inactivo"
                                messageService.success(`El registro se ${activate ? 'activó' : 'desactivó'} exitosamente.`);          
                            }).catch(reject => {
                                getMessageError(reject, messageService);
                            });
                        }
                    }
                );
                
            }
        }

        const onApplyFilters = (values) => {
            criteriaFilters = '';
            criteriaFilters = addCriteria(values, "WarehouseId", criteriaFilters, true);
            criteriaFilters = addCriteria(values, "Name", criteriaFilters, true);
            criteriaFilters = addCriteria(values, "Code", criteriaFilters, true);
            criteriaFilters = addCriteria(values, "AlternateCode", criteriaFilters, true);
            criteriaFilters = addCriteria(values, "Description", criteriaFilters, true);
            criteriaFilters = addCriteria(values, "FamilyId", criteriaFilters, true); 
            criteriaFilters = addCriteria(values, "SubfamilyId", criteriaFilters, true);
            criteriaFilters = addCriteria(values, "ColorId", criteriaFilters, true); 
            criteriaFilters = addCriteria(values, "SupplierId", criteriaFilters, true);
            criteriaFilters = addCriteria(values, "Lessminimum", criteriaFilters, false);
            criteriaFilters = addCriteria(values, "lexistence", criteriaFilters, false);
            getPagedItems();
        }

        const addCriteria = (values, paramName, currentCriteria, isText) => {
            if(values[paramName] != null && values[paramName] != undefined) {
                let paramValue = isText ? values[paramName].trim() : values[paramName];
                if(paramValue != null) {
                    currentCriteria = (currentCriteria != '' ? `${currentCriteria}&` : '') + `Criteria.${paramName}=${paramValue}`;
                }  
            }
            
            return currentCriteria;
        }

        const getAppliedFilters = () => {
            let filters = ""
            let objPropName = "";
            let objPropVal = "";
            if(criteriaFilters != '')
            {
                if(criteriaFilters.includes('Criteria.Name')){
                    const lstFilters = [];
                    const itemFilters = criteriaFilters.split('&');
                    lstFilters.push(...itemFilters);
                    lstFilters.forEach(element => {
                        const valelement = element.split('=')
                        objPropName = valelement[0].replace("Criteria.", "")
                        objPropVal = objPropName == "Name" ? (textSearch.value != '' ? textSearch.value : valelement[1]) : valelement[1];
                        filters += (filters == "" ? '':'&') + (`${objPropName}=${objPropVal}`)
                    });                    
                }else{
                    filters = criteriaFilters;
                    filters = filters.replace("Criteria.", "");
                    if(textSearch.value.trim() != "")
                        filters = filters + `&Name=${textSearch.value}`
                }
            }else{
                if(textSearch.value.trim() != "")
                    filters = `Name=${textSearch.value}`
            }

            return filters;
        }

        const downloadcsvpodruct = () => {
            let filters = getAppliedFilters();
            let url = `${controller}/download?${filters}`;
            ApiService.query(url).then(res => {
                const dtCurrent = new Date();
                const fecha = ("0" + dtCurrent.getDate()).slice(-2) + "" + ("0"+(dtCurrent.getMonth()+1)).slice(-2) + "" +
                                dtCurrent.getFullYear() + "" + ("0" + dtCurrent.getHours()).slice(-2) + "" + ("0" + dtCurrent.getMinutes()).slice(-2) + ""
                               + ("0" + dtCurrent.getSeconds()).slice(-2)
                const anchor = document.createElement('a');
                anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(res.data);
                anchor.target = '_blank';
                anchor.download = 'ReporteArticulos' + fecha + '.csv';
                anchor.click();
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onCleanFilters = (values) => {
            criteriaFilters = values;
        }

        const downloadLayout = () => {
            let filters = getAppliedFilters();
            let url = `${controller}/exportLayout?${filters}`;
            ApiService.dowloadFile(url).then(res => {
                const blob = new Blob([res.data], {
                    type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                })
                const url = window.URL.createObjectURL(blob);
                if (url) {
                    window.open(url);
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        return {
            //variables y constantes
            labels
            , btnFilters
            , formCatalogFilters
            , btnChangePrices
            , formChangePrices
            , btnDownloadKardex
            , formDownloadKardex
            , btnDownloadReport
            , formDownloadReport

            //varaibles computadas
            , headers
            , renderRows
            , totalRecords
            , selectedPage
            , recordsPerPage

            //funciones
            , handleChangePage
            , searchItems
            , handleCrudAction
            , callbackColumnClick
            , toolbarAction
            , onApplyFilters
            , changePageSize
            , downloadcsvpodruct
            , onCleanFilters
        }
        
    },
})
</script>
